@import './mixins.scss';
@import './constants.scss';
@import './elements.scss';
@import '../../node_modules/mapbox-gl/dist/mapbox-gl.css';
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@400;700&display=swap');

:root {
  font-size: 16px;
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family:  'Teko', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $darkLighter;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.main-container {
  display: flex;
  overflow: hidden;
}

.mapboxgl-popup-close-button {
  top: 5px;
  right: 5px;
}