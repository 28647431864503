.full-loader {
    position: absolute;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    z-index: 1000;
    background: $darkLighter;
}

.circle-marker {
    width: 100px;
    height: 100px;
    z-index: 2;
    border-radius: 50%;
    background-color: transparentize($color: $red, $amount: 0.25);
}

.pop-container {
    z-index: 10;
    span {
        display: flex;
        align-items: center;
        img {
            margin-right: 20px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .count {
        display: flex;
        align-items: flex-end;
        h2 {
            color: $red;
            font-size: 2rem;
            margin-right: 10px;
        }
    
        h3 {
            font-size: 2rem;
        }
    }
}

.categories {
    display: flex;
    border-radius: 4px;
    background: $darkLighter;
    border: 1px solid $gray;
    .category-item {
        flex: 1;
        padding: 15px 20px;
        display: flex;
        justify-content: center;
        color: $gray;
        transition: all 0.3s ease-in-out;
        user-select: none;
        &:hover {
            cursor: pointer;
            color: #fff;
        }

        &.active {
            background: $dark;
            border: 1px solid $gray;
            color: #fff;
            border-bottom: 3px solid $yellow;
        }
    }

    @include mobile {
        margin: 0 20px;
    }
    @include tablet {
        margin: 0 20px;
    }
}

.list-container {
    height: 210px;
    margin-top: 30px;
    overflow-y: scroll;
    padding: 10px;
    span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 4px;
        transition: all 0.3s ease-in-out;
        img {
            width: 30px;
            height: 30px;
            object-fit: cover;
            border-radius: 50%;
        }

        &.active-country {
            background-color: $yellow;
            color: #fff;
        }

        &:hover {
            cursor: pointer;
            background-color: $darkLighter;
        }
    }

    &::-webkit-scrollbar {
        width: 4px;
        background: $dark;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $gray;
        border-radius: 5px;

        &:hover {
            background-color: $darkLighter;
        }
    }
}

.graph-container {
    width: 100%;
}

.graph-error {
    display: flex;
    height: 300px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
        margin-top: 10px;
    }
}