$mobile: "(min-width: 320px) and (max-width: 765px)";
$tablet: "(min-width: 768px) and (max-width: 1024px)";
$desktop: "(min-width: 1025px)";

$dark: #161718;
$darkLighter: #282b2e;
$accent: #ed9b3b;
$red: #b93636;
$red: #b93636;
$yellow: #f6c778;
$cyan: #5cc1ac;
$gray: #4f4e53;